@use "./config/" as *;
@forward "./main-scss/";
@forward "./partials/form-common";
.waf-breadcrumb {
    @extend %d-none;
}
.main-wrap {
    min-height: unset;
}
.waf-login {
    .otp {
        &-wrap {
            @extend %gap-4;
            @extend %flex;
        }
        .form-input {
            width: 4.3rem;
            padding: 0;
            @extend %text-center;
        }
        &.otp-failed {
            .form-input {
                border: .1rem solid var(--error-800);
                color: var(--error-800);
            }
        }
        &.otp-success {
            .form-input {
                border: .1rem solid var(--success-800);
                color: var(--success-800);
            }
        }
    }
    .resend-otp {
        justify-content: end;
        border-bottom: .1rem solid var(--neutral-1000);
        @extend %pb-7;
        @extend %gap-3;
        @extend %flex-column;
        @extend %text-right;
        .text {
            @extend %secondary-800;
            @extend %font-14-pr;
            .otp {
                font-weight: 700;
                @extend %pl-1;
                @extend %secondary-800;
            }
        }
    }
    .radio-box-wrap {
        @extend %flex;
        @extend %gap-4;
        @extend %mb-6;
        @extend %mt-3-neg;
        .radio-container {
            cursor: pointer;
            user-select: none;
            @extend %relative;
            @extend %pl-7;
            @extend %capitalize;
            @extend %font-14-pm;
            @extend %neutral-800;
            input {
                opacity: 0;
                left: 0;
                cursor: pointer;
                @extend %d-none;
                @extend %pos-y-center;
                &:checked {
                    ~ .checkmark {
                        border: 0.1rem solid var(--primary-300);
                        &:after {
                            content: '';
                        }
                    }
                }
            }
            .checkmark {
                height: 1.8rem;
                aspect-ratio: 1/1;
                border: 0.1rem solid var(--neutral-800);
                left: 0;
                @extend %circle-radius;
                @extend %pos-y-center;
                &:after {
                    width: 1rem;
                    aspect-ratio: 1/1;
                    @extend %circle-radius;
                    @extend %primary-300-bg;
                    @extend %pos-center;
                }
            }
        }
    }
    .email-address {
        .form-input {
            text-transform: none;
        }
    }
}
@include mq(col-lg) {
    .main-wrap {
        min-height: var(--window-inner-height);
    }
    .waf-login {
        .form-wrapper {
            width: 40.4rem;
            margin: auto;
        }
        .otp {
            .form-input {
                width: 5.4rem;
            }
        }
        .custom-form {
            display: unset;
            .form-group {
                width: 100%;
            }
        }
        .radio-box-wrap {
            margin-bottom: var(--space-8);
        }
    }
}